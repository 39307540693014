//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { contactsStore } from '@/store'

export default {
  name: 'ParsedTask',
  components: {
  },
  props: {
    num: {
      type: Number,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: null,
    },
    assignee: {
      type: String,
      required: false,
      default: null,
    },
    deadline: {
      type: String,
      required: false,
      default: null,
    },
    section: {
      type: String,
      required: false,
      default: null,
    },
    tags: {
      type: Array,
      required: false,
      default: null,
    },
    isPublic: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: String,
      required: false,
      default: null,
    },
  },
  data () {
    return {
    }
  },
  computed: {
    parsedDeadline () {
      if (!this.deadline) return null
      return Intl.DateTimeFormat(
        'default',
        {
          hour: 'numeric',
          minute: 'numeric',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        },
      ).format(new Date(this.deadline))
    },
    showCaptionLine () {
      return this.deadline || this.section || this.isPublic
    },
    contact () {
      return contactsStore.getters.contact(this.assignee)
    },
    avatarSrc () {
      return contactsStore.getters.contactIcon(this.assignee)
    },
  },
  created () {
  },
  beforeDestroy () {
  },
  methods: {
  },
}
